<template>
  <div class="front-cont">
    <div class="img-bg row pb-5 pb-md-4 pt-6 pt-md-4 f-page-bg" style="opacity: 0.95">
      <div class="f-contact-content my-2">
        <div class="row p-3 p-md-4 bg-white contact-box">
          <div class="col-md-6">
            <h1 class="section-title text-dark mt-1 mb-2">
              {{ PageData.pagetitle }}
            </h1>
            <p class="mb-1" v-html="PageData.content"></p>
          </div>

          <div class="col-md-6">
            <div class="d-md-flex align-items-end mb-1 mb-md-2 mt-2">
              <label for="contact-title" class="contact-fd-label">{{ $t.translate("FP_P_TITLE") }}</label>
              <el-select
                v-model="formData.personTitle"
                placeholder="--"
                class="disclosure-select w-100"
                popper-class="front-popper"
                id="contact-title"
              >
                <el-option
                  v-for="item in PersonTitleOpts"
                  :key="'pt-opt-' + item.value"
                  :label="$t.translate(item.label)"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <div class="d-md-flex align-items-end mb-1 mb-md-2">
              <label for="contact-fn" class="contact-fd-label">{{ $t.translate("FP_FIRSTNAME") }}</label>
              <el-input
                v-model="formData.firstName"
                placeholder="" 
                class="search-input"
                id="contact-fn"
              ></el-input>
            </div>

            <div class="d-md-flex align-items-end mb-1 mb-md-2">
              <label for="contact-ln" class="contact-fd-label">{{ $t.translate("FP_LASTNAME") }}</label>
              <el-input
                v-model="formData.lastName"
                placeholder="" 
                class="search-input"
                id="contact-ln"
              ></el-input>
            </div>

            <div class="d-md-flex align-items-end mb-1 mb-md-2">
              <label for="contact-email" class="contact-fd-label">{{ $t.translate("FP_EMAIL") }}</label>
              <el-input
                v-model="formData.email"
                placeholder="" 
                class="search-input"
                :class="{
                  'inp-invalid':
                    !$h.isBlank(formData.email) && !$h.validateEmail(formData.email),
                }"
                id="contact-email"
              ></el-input>
            </div>

            <div class="d-md-flex align-items-end mb-1 mb-md-2">
              <label for="contact-reg" class="contact-fd-label">{{
                $t.translate("FP_COUNTRY_REGION")
              }}</label>
              <el-select
                v-model="formData.region"
                placeholder="--"
                class="disclosure-select w-100"
                popper-class="front-popper"
                id="contact-reg" 
              >
                <el-option
                  v-for="item in countryCodeList"
                  :key="'rg-opt-' + item.country"
                  :label="$t.translateCountry(item)"
                  :value="item.country"
                ></el-option>
              </el-select>
            </div>

            <div class="">
              <label for="contact-desc" class="contact-fd-label">{{
                $t.translate("FP_HELP_CONTENT")
              }}</label>
              <el-input
                v-model="formData.description"
                placeholder="" 
                class="search-input"
                :rows="3"
                resize="none"
                type="textarea"
                id="contact-desc"
              ></el-input>
            </div>

            <div class="mb-2">
              <el-button
                type="info"
                @click="submitForm"
                class="f-btn-common f-btn-orange mt-4"
              >
                <b>{{ $t.translate("BTN_SUBMIT") }}</b>
              </el-button>
            </div>
          </div>

          <div class="col-md-12 mt-4">
            <h3 class="text-dark mt-1 mb-3">
              {{ PageData?.Office?.pagetitle }}
            </h3>
          </div>

          <div v-for="item in AddressList" :key="item.id" class="col-md-4">
            <h4 class="mb-1 f-text-orange">{{ item.pagetitle }}</h4>
            <p class="mb-1 text-dark smaller">{{ item.longtitle }}</p>
            <p class="mb-1 text-dark smaller" v-html="item.content"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, onUpdated, ref, reactive, computed } from "vue";
import { useRoute } from "vue-router";
import { post, get, isValidResponse } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { router } from "@/utils/router";
import { store } from "@/store";

export default {
  setup() {
    const route = useRoute();
    let PageData = ref({});
    let formData = reactive({
      personTitle: null,
      firstName: null,
      lastName: null,
      email: null,
      region: null,
      description: null,
    });

    let AddressList = ref([]);
    let { PersonTitleOpts } = store.getters["config/getData"]?.envConf;
    let { countryCodeList } = store.getters["config/getData"]?.envConf;

    onMounted(async () => {
      loadItems();
    });

    onUpdated(() => {});

    const loadItems = async () => {
      let res = await get("/cms/get/contact.json", {}, true);
      PageData.value = res?.PageData || {};

      let office = await get("/cms/get/office-addresses.json", {}, true);
      PageData.value.Office = office?.PageData;

      AddressList.value = office?.List || [];
    };

    const submitForm = async () => {
      if (
        helpers.isBlank(formData.personTitle) ||
        helpers.isBlank(formData.firstName) ||
        helpers.isBlank(formData.lastName) ||
        helpers.isBlank(formData.email) ||
        !helpers.validateEmail(formData.email) ||
        helpers.isBlank(formData.region) ||
        helpers.isBlank(formData.description)
      ) {
        helpers.catchError({ Code: "MSG_ERR_REQUIRED_FIELD" });
        return;
      }

      let res = await post("/public/contact", formData);
      if (!isValidResponse(res)) return;

      let keys = Object.keys(formData);
      for (let key of keys) {
        formData[key] = "";
      }

      helpers.showMessage({
        Code: "MSG_SUCCESS",
        Message: "Attachment removed successfully",
      });
    };

    return {
      PageData,
      formData,
      AddressList,
      PersonTitleOpts,
      countryCodeList,
      loadItems,
      submitForm,
    };
  },
};
</script>
<style>
.f-contact-content {
  /* position: absolute; */
  width: 100%;
  min-height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-bg {
  background-size: cover;
  min-height: 75vh;
}
.contact-box {
  border-radius: 8px;
  width: 900px;
  opacity: 0.92;
}
.front-cont .search-input.inp-invalid .el-input__inner,
.front-cont .search-input.inp-invalid .el-textarea__inner {
  border-radius: 8px;
  border: 1px solid #fb6340;
}
.contact-fd-label {
  font-size: var(--f-size-label);
  min-width: var(--min-w-label);
  color: #333;
}

@media (max-width: 900px) {
  .contact-box {
    width: 88%;
  }
  .contact-fd-label {
    font-size: var(--f-size-label);
    min-width: var(--min-w-label);
    margin-right: 8px;
    margin-bottom: 0px;
  }
}
</style>
